<template>
  <div>
    <div v-for="(item,index) in needData" :key="index">
    <h2 style="text-align: center">案主问题及需求——{{index}}</h2>
    <Form
      :model="item"
      :inline="true"
      :label-colon="true"
      :disabled="true"
      class="needForm"
    >
      <FormItem :label="subIndex" style="width: 100%" v-for="(subItem,subIndex) in item" :key="subIndex">
        <FormItem
          :label="k.questionDesc"
          style="width: 100%; padding: 0 26px"
          v-for="(k,i) in subItem"
          :key="i"
        >
          <Input
            :maxlength="100"
            v-model.trim="k.questionResult"
            style="width: calc(100% - 300px)"
          ></Input>
        </FormItem>
      </FormItem>
    </Form>

    <Divider />
    </div>
  </div>
</template>

<script>
export default {
  props: ["questionList"],
  data() {
    return {
      needData: {
        个体层面: {},
        家庭层面: {},
        社会层面: {},
      },
    };
  },
  methods: {
    getQuestionList() {
      this.questionList.map((item) => {
        if (item.topType == 1) {
          if (!this.needData["个体层面"].hasOwnProperty(item.questionType)) {
            //判断当前对象是否有这个属性，没有就添加
            this.needData["个体层面"][item.questionType] = [];
          }
          this.needData["个体层面"][item.questionType].push({
            questionId: item.questionId,
            questionDesc: item.questionDesc,
            questionResult: item.questionResult,
          });
        } else if (item.topType == 2) {
          if (!this.needData["家庭层面"].hasOwnProperty(item.questionType)) {
            //判断当前对象是否有这个属性，没有就添加
            this.needData["家庭层面"][item.questionType] = [];
          }
          this.needData["家庭层面"][item.questionType].push({
            questionId: item.questionId,
            questionDesc: item.questionDesc,
            questionResult: item.questionResult,
          });
        } else {
          if (!this.needData["社会层面"].hasOwnProperty(item.questionType)) {
            //判断当前对象是否有这个属性，没有就添加
            this.needData["社会层面"][item.questionType] = [];
          }
          this.needData["社会层面"][item.questionType].push({
            questionId: item.questionId,
            questionDesc: item.questionDesc,
            questionResult: item.questionResult,
          });
        }
      });
    },
  },
  created() {
    this.getQuestionList();
  },
};
</script>
    
<style scoped lang='less'>
.ivu-form-item {
  margin-bottom: 16px;
  .ivu-input-wrapper {
    width: 200px;
    /deep/input {
      border: 0;
      border-bottom: 1px solid #65656c;
      border-radius: 0;
    }
    /deep/input:hover,
    /deep/input:focus {
      border: 0;
      border-bottom: 1px solid #65656c;
      box-shadow: none;
    }
  }
  /deep/.ivu-input[disabled],
  fieldset[disabled] .ivu-input {
    background-color: #fff !important;
    color: #515a6e;
  }
}
</style>